import React from 'react';
import WomenswearIcon from '@fuww/library/src/Icons/Womenswear';
import MenswearIcon from '@fuww/library/src/Icons/Menswear';
import KidswearIcon from '@fuww/library/src/Icons/Kidswear';
import ShoesIcon from '@fuww/library/src/Icons/Shoes';
import AccessoriesIcon from '@fuww/library/src/Icons/Accessories';
import BagsIcon from '@fuww/library/src/Icons/Bags';
import BrandsIcon from '@fuww/library/src/Icons/Brands';
import ColorsIcon from '@fuww/library/src/Icons/Colors';

const marketplaceSegments = [
  {
    title: 'Womenswear',
    icon: <WomenswearIcon />,
    route: 'products',
    params: {
      marketId: '1',
      department: 'Women',
    },
  },
  {
    title: 'Menswear',
    icon: <MenswearIcon />,
    route: 'products',
    params: {
      marketId: '1',
      department: 'Men',
    },
  },
  {
    title: 'Kidswear',
    icon: <KidswearIcon />,
    route: 'products',
    params: {
      marketId: '1',
      department: 'Kids',
    },
  },
  {
    title: 'Shoes',
    icon: <ShoesIcon />,
    route: 'products',
    params: {
      marketId: '1',
      category: 'Footwear',
    },
  },
  {
    title: 'Accessories',
    icon: <AccessoriesIcon />,
    route: 'products',
    params: {
      marketId: '1',
      category: 'Accessories',
    },
  },
  {
    title: 'Bags',
    icon: <BagsIcon />,
    route: 'products',
    params: {
      marketId: '1',
      category: 'Bags',
    },
  },
  {
    title: 'Brands',
    icon: <BrandsIcon />,
    route: 'market-brands',
    params: {
      marketId: '1',
    },
  },
  {
    title: 'Colors',
    icon: <ColorsIcon />,
    route: 'products',
    params: { marketId: '1' },
  },
];

export default marketplaceSegments;
